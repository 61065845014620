import React from 'react'
import IntegrationTemplate from '../../components/Integrations/IntegrationTemplate'

import bigcartelLogo from '../../images/integration-logo-images/bigcartel.png'

const BigCartel = () => {
    return (
        <>
            <IntegrationTemplate 

                metaTitle="Integrate Optinly with Big Cartel"
                metaDescription="Optinly is the smart pop-up builder for your Big Cartel store. With Optinly, you can seamlessly attract leads to grow your email list and reduce cart abandonment."
            
                otherProductLogo={bigcartelLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="Big Cartel"
                homeTitle="Grow Your Email List with Big Cartel Popups"
                homeDescription="Integrate Optinly with your Big Cartel store. Ideal lead generation tool with visitor engaging popups to grow your email list. Increase Sales with smart popups. "

                docsTitle="Increase your Big Cartel store email list "
                docsDescription="Best Big Cartel popup app. A complete package to rapidly grow your email list, leads and subscribers. Select from pre-made popups or custom popup in your Big Cartel store. "

                featruesDocsSection1="Integeration and Customization "
                featruesDocsTitle1="Seamless Integeration and Advance Customization Options"
                featruesDocsDescription1="Customize popups based on user behavioural pattern. Design or development skill not required to customize popup. Big Cartel email popup through simple integeration. "

                featruesDocsSection2="Installation and Signup"
                featruesDocsTitle2 ="Big Cartel Popups To Improve Store Performance"
                featruesDocsDescription2="Hassle-free signup, download, and installation. Best Big Cartel popup form to deliver customer-friendly popup. Select the best email popup for Big Cartel from numerous existing templates."

                featruesDocsSection3="Achieve Marketing Goals "
                featruesDocsTitle3 ="One-stop Solution for Big Cartel Popup building"
                featruesDocsDescription3="Everything you need to create user engaging popups in one place. Advacned dashboard, simple integeration, regular update of pre-made templates and a clear user interface."

                featruesDocsSection4="Detailed Dashboard Analytics"
                featruesDocsTitle4 ="All-in-one Dashboard"
                featruesDocsDescription4="Know how your Big Cartel Popup is performing. Manage campaigns, make changes, and more with a powerful dashboard. Simple to navigate yet highly accurate analytics available."

                FAQQuestion1="Does Optinly offer Big Cartel email popup?  "
                FAQAnswer1="Optinly offers multiple redaymade Big Cartel email popups. Also, it provides options to easily customize the popup depending upon the user. Big Cartel email popups are popups used in Big Cartel websites for collecting quality customer email address. "

                FAQQuestion2="How many types of Big Cartel popup form is available in Optinly?"
                FAQAnswer2="Optinly offers 6+ types of unique popup forms for Big Cartel. Each one is engaging and grabs audience attentation without any delay. "

                FAQQuestion3="Is there a free pricing plan available in Optinly for Big Cartel stores? " 
                FAQAnswer3="Optinly have a free pricing plan that works on Big Cartel stores. Feel free to check out the entire pricing structure in Optinly pricing page.  "

                FAQQuestion4="Is Big Cartel exit popup available in Optinly? "
                FAQAnswer4="Yes, Optinly have customer engaging exit popup templates. You can personalize the template or made use of the pre-made one. "
            />
        </>
    )
}

export default BigCartel
